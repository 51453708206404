<template>
  <div class="circleQglsz">
    <pageHead class="messageMainView_head" title="圈管理设置" />
    <div class="circle_xz">管理员:({{ vanSwipeList.length }}/3)</div>
    <!-- 列表 -->
    <div class="vant_List" v-if="vanSwipeList.length > 0">
      <van-swipe-cell v-for="(item) in vanSwipeList" :key="item.id">
        <van-card
          :title="item.title"
          class="goods-card"
          :thumb="item.header"
        />
        <span class="right_c" @click.prevent="right_c(item)"></span>
      </van-swipe-cell>
    </div>

    <div class="circle_button">
      <span class="icon_pic"></span>
      <span class="circle_buton_Text" @click="toCircleGL">添加管理员</span>
    </div>
  </div>
</template>

<script>
import pageHead from '@/components/pageHead'

export default {
  name: 'circleQglsz', // 圈管理设置
  components: {
    pageHead,
  },
  data() {
    return {
      vanSwipeList: [
        {
          id: 1,
          title: '极氪员工-李三',
          isCheck: false,
          header: 'https://img01.yzcdn.cn/vant/cat.jpeg'
        },
        {
          id: 2,
          title: '极氪员工-李四',
          isCheck: false,
          header: 'https://img01.yzcdn.cn/vant/cat.jpeg'
        },
        {
          id: 3,
          title: '极氪员工-王五',
          isCheck: false,
          header: 'https://img01.yzcdn.cn/vant/cat.jpeg'
        },
      ],
    }
  },
  methods: {
    toCircleGL() {
      this.$router.push({name: "circleQgly"});
    },
    // 移除
    right_c(item) {
      console.log(item)
      this.$dialog.confirm({
        title: '移除管理员',
        confirmButtonText: '确定',
        cancelButtonText: '再考虑下',
        message: '确定移除当前管理员?',
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.circleQglsz {
  .circle_xz {
    font-size: 12px;
    color: #6d7278;
    margin: 13px 22px;
  }

  .circle_button {
    width: 355px;
    height: 48px;
    background: #ffffff;
    border-radius: 6px;
    margin: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    .icon_pic {
      width: 14px;
      height: 14px;
      margin-right: 4px;
      background: url('~@/assets/imgs/circle/Slice53_+.svg');
      background-size: cover;
    }

    .circle_buton_Text {
      font-size: 14px;
      color: #fe5800;
    }
  }

  .vant_List {
    margin-top: 13px;
    margin: 0 10px 0 10px;
    padding-top: 15.88px;
    background: #fff;
    border-radius: 6px;
    overflow: scroll;

    ::v-deep{
      .van-swipe-cell__wrapper {
        display: flex;
        position: relative;
        border-bottom: 1px solid #f4f4f4;
      }

      .van-card__title {
        padding-left: 0;
      }

      .van-card {
        padding-left: 10px;
        padding: 0 0 0 10px;
      }

      .van-card:not(:first-child) {
        margin-top: 0;
      }

      .van-swipe-cell:first-child {
        margin-top: 0;
      }

      .van-swipe-cell {
        margin-top: 16px;
        padding: 0 15px;
      }
    }

    .goods-card {
      border-bottom: 1px solid #f4f4f4;
    }

    .right_c {
      width: 14px;
      height: 14px;
      position: absolute;
      right: 0;
      top: 22%;
      display: inline-block;
      background: url('~@/assets/imgs/circle/Slice__48.svg');
      background-repeat: no-repeat;
    }
  }
}
</style>
